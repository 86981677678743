@import './../../assets/styles/zcmthemes';

.big-number-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.search-text-main,
.resize-title>h1 {
  font: normal normal bold 1.5rem Raleway;
}

.resize-title {
  margin-top: 7rem;
}

.big-number {
  text-align: center;

  .number {
    font-size: 7em;
  }

  .spinner-container {
    min-height: 96px;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.dash-list {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  height: 100%;
  padding-top: 2rem;
  padding-bottom: 4rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, minmax(auto, 33.333%));
  }

  @media screen and (max-width: 768px) {
    height: 65rem;
  }

}

.card-chart {
  background-color: $light-gray;
  font-family: $font-family;
  color: $gray;
  border-radius: 16px;
  //font-size: 1.5rem;
  font-weight: 600;
}

.projects-data {
  font-family: $font-family;
  font-size: 1.5rem;
  font-weight: 600;

}