

.image-box {
  img {
      width: 100%;
      height: 214px;
      object-fit: cover;
  }
}

