@import './../../assets/styles/zcmthemes';

.footer-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 2rem 0;


    @media (max-width: $tablet) {
        flex-direction: column;
    }

    .title {
        font-family: $font-family;
        font-weight: 700;
        font-size: 24px
    }

    .institutional-box {

        .about {
            >a {
                color: inherit;
                text-decoration: none;
            }
        }
    }


    .social-media-box {
        margin: 0 2rem;

        @media (max-width: $tablet) {
            margin: 2rem 0;
        }


        .social-media {
            display: flex;
            align-content: center;

            .social-media-item {
                display: flex;
                flex-flow: row nowrap;
                color: inherit;
                text-decoration: none;

                .yt-logo {
                    width: 22px;
                    margin: 0 0.25rem;
                }
            }
        }
    }

    img {
        width: auto;
        max-height: 200px;
    }
}