@import 'zcmthemes';
@import 'vendors';

.content {
    display: flex;
    flex-flow: column;
    align-items: center;

    .app-container {
        width: 100%;
        padding: $appContainerDesktopPadding;
        max-width: 1399px;

        @media screen and (max-width: 992px) {
            padding: $appContainerMobilePadding;
        }

    }

}

.footer-separator {
    border-top: 1px solid $light-gray;
    width: 100%;
}

.container-fluid {
    padding: 0;
}

.communities {
    margin-right: 10px;
}

.dorothy {
    display: flex;
}

.dorothy-communities {
    flex: 1;
    background-color: #fff;
    padding: 20px;
    /* height: calc(100vh - 120px); */
    height: 100vh;
}

.community-title {
    margin: 5px 0;
}

.community-title a,
.community-title a:hover,
.community-title a:visited {
    text-decoration: none;
    color: #666;
}

.dorothy-community {
    flex: 8;
    margin: 10px 10px;
}

.dorothy-communities-title {
    margin-bottom: 20px;
}

.active-community {
    font-weight: bold;
}

.footer {
    text-align: right;
}

.pessoal {
    margin: 20px;
    font-size: 20px;
}

.dorothy-communities-title-close {
    display: none;
}

.open-communities-menu-button {
    display: none;
}

.version {
    display: none;
}

@media only screen and (max-width: 600px) {
    .dorothy-communities {
        display: none;
        position: absolute;
        z-index: 1000;
        box-shadow: 4px 1px 7px #999;
    }

    .dorothy-communities-title-close {
        position: relative;
        width: 100%;
        top: -10px;
        text-align: center;
        display: block;
        background-color: #ccc;
        color: #fff;
        padding: 3px;
        border-radius: 10px;
        font-size: 11px;
        font-weight: bold;
    }

    .open-communities-menu-button {
        display: inline-block;
    }

    .dorothy-communities-title-close a,
    .dorothy-communities-title-close a:visited,
    .dorothy-communities-title-close a:hover {
        text-decoration: none;
        color: #fff;
    }

    .login-div,
    .login-div-err {
        width: 45%;
    }

    .open-comm-menu {
        display: block;
    }

    .version {
        display: block;
        font-size: 0.75em;
        margin-top: 25px;
    }
}

.login-title {
    margin-bottom: 20px !important;
}

.login-button {
    margin-top: 10px !important;
}

.forgot-button {
    margin-top: 20px !important;
}

.bp3-tab:focus,
.bp3-button:focus {
    outline: none;
}

.my-communities {
    margin-bottom: 20px;
}

.navbar_h {
    display: flex;
    align-items: center;
}

.navbar_h div {
    margin-right: 10px;
}

.navbar_h img {
    height: 25px;
}

.navbar_h span {
    margin-left: 10px;
    font-size: 18px;
    position: relative;
    top: -6px;
}

.tool_menu_container {
    width: 100%;
    text-align: center;
}

.tool_menu {
    margin: 0 auto;
}

.tool_menu button {
    margin: 10px 0;
}

.charts-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    max-height: 70vh;
}

.charts-container>div {
    flex: 1;
}

.charts-container>div>div {
    margin: 0 auto;
}

.map-container {
    flex-grow: 1;
    z-index: 1;
    /* background-color: #d3d8d4; */
    position: relative;
}

.map-container>div {
    width: 100%;
    height: 100%;
}

.general-container {
    margin: 10px 0;
}

.orders {
    width: 100%;
}

.group-list {
    font-size: 0.85em;
}

.incomplete-group {
    color: #666;
}

.tag-container {
    margin-left: 20px;
}

.tag-alert {
    margin: 0 5px;
}

.alert-low {
    /* background-color: rgba(217, 130, 43, 0.3) !important; */
    border-bottom: 5px solid #f9c6237a !important;
}

.alert-high {
    /* background-color: rgba(219, 55, 55, 0.3) !important; */
    border-bottom: 5px solid #dc3e4d7a !important;
}

.consolid {
    background-color: #0091644d !important;
}

.inventory-container,
.tc-container {
    overflow-y: scroll;
    max-height: 60vh;
}

/* .tc-container {
  overflow-y: scroll;
  max-height: 50vh;
} */

.currentCommunity {
    color: #333;
}

.version-number {
    font-size: 0.65em !important;
    opacity: 0.5;
}

.btn-light:disabled {
    background: none;
    color: rgba(92, 112, 128, 0.6);
    cursor: not-allowed;
}

.btn svg {
    margin: 0 5px;
}

textarea:focus,
input:focus {
    outline: 0;
}

*:focus {
    outline: 0;
}

.brand {
    align-items: center;
}

.brand>div {
    margin: 0 5px;
}

.b-example-divider {
    height: 3rem;
    background-color: rgba(0, 0, 0, 0.1);
    border: solid rgba(0, 0, 0, 0.15);
    border-width: 1px 0;
    box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1), inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

.form-control-dark {
    color: #fff;
    background-color: var(--bs-dark);
    border-color: var(--bs-gray);
}

.form-control-dark:focus {
    color: #fff;
    background-color: var(--bs-dark);
    border-color: #fff;
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
}

.bi {
    vertical-align: -0.125em;
    fill: currentColor;
}

.text-small {
    font-size: 85%;
}

.dropdown-toggle {
    outline: 0;
}

.pagination>.active,
.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    background-color: #E6F6FF;
    border-color: #2C9AD9;
}

.page-link:focus {
    box-shadow: none;
    background-color: red !important;
}

.page-link {
    border-radius: 4px;
    margin-left: 2px;
}

.pagination {
    justify-content: right;
    margin-top: 20px;
}

tr:last-child td {
    border-bottom: none;
}

.incomplete {
    color: red !important;
}