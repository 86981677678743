@import './../../assets/styles/zcmthemes';

.news-title {
    font-family: $font-family;
    color: $gray;
    font-size: 32px;
}

.news-info-table {
    color: $gray;
    font-family: $font-family;
    font-size: 20px;

    th,
    td {
        padding-right: 2rem;
        padding-bottom: 0.5rem;
    }
}

.news-content {
    font-family: $font-family;
    font-size: 20px;
    color: $gray;
    text-align: justify;
}

.news-image-box {
    img {
        width: 100%;
        height: 600px;
        object-fit: cover;
    }
}