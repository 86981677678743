@import './../../assets/styles/zcmthemes';

.root {
    display: flex;
    justify-content: space-between;
    user-select: none;
    position: sticky;
    z-index: 999;
    align-items: center;
    width: 100%;
    padding: 1rem 3rem;
    align-items: stretch;

    .header_* {
        height: 100%;
    }

    .header_start {
        display: flex;
        align-items: center;


        .logo {
            max-width: 100%;
            height: auto;

            @media (max-width: $mobileL) {
                height: 3rem !important;
            }

            @media (max-width: $mobileM) {
                height: 2.5rem !important;
            }

        }
    }

    .header_center {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        list-style: none;
        gap: 1rem;
        font-weight: 500;
        width: 100%;

        .menu {
            box-shadow: $shadow;
            padding: .875rem;
            position: absolute;
            background-color: $white;
            border-radius: .5rem;
        }

        .styled-raw-link {

            color: $gray;
            text-decoration: none;

            &:hover {
                opacity: 0.75;
            }

            .menu-item {
                color: gray;
                font-size: 18px;
                cursor: pointer;
            }
        }
    }

    .header_end {
        display: flex;
        justify-content: space-between;
        gap: 1rem;

    }

    /* buttons */
    .button-link {
        font-size: 1rem;
        font-weight: 500;
        color: $gray;
        cursor: pointer;
        font-family: $font-family;
        transition: .5s ease;
        display: flex;
        align-items: center;
        background: transparent;
        border: 0;

        svg {
            height: 1rem;
            margin-right: .25rem;
        }

        &:hover {
            color: $primary;
        }

        &.reverse {
            svg {
                margin-right: 0;
                margin-left: .25rem;
            }
        }

        &.gray {
            color: $gray;

            &:hover {
                color: $gray;
                opacity: .75;
            }
        }
    }

    .button-sign {
        min-width: 6rem;
        text-decoration: none;
        /* margin-left: 1rem; */
        padding: .1rem .5rem;
        border: 1px solid #73bf48;
        border-radius: 1rem;
        font-size: 1.0rem;
        font-weight: 500;
        box-sizing: border-box;
        background: #73bf48;
        color: #fff;
        cursor: pointer;
        font-family: "Raleway", sans-serif !important;
        transition: 0.5s ease;

        &:hover {
            opacity: 0.75;
        }
    }

}

@media (max-width: $tablet) {
    .root {
        flex-wrap: wrap;

        .header_start,
        .header_end {
            order: 1;
        }

        .header_center {
            order: 2;
            align-content: center;
        }
    }
}

/* 
.user-account-actions {} */