@import './../../assets/styles/zcmthemes';

.project-container {
    max-width: 1240px !important;
    margin-left: auto;
    margin-right: auto;

    .value {
        font-weight: normal;
    }

    .details {
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .styled-access-button {
        border-radius: 50px;
        border-color: $primary;
        color: $primary;

        &:hover {
            color: $white;

        }
    }

    .separator {
        margin-right: 5px;
    }

    .info-table {
        color: $gray;

        th,
        td {
            padding-right: 2rem;
            padding-bottom: 0.5rem;
        }
    }

}