@import './../../assets/styles/zcmthemes';



.news-grid-box {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: stretch;
    align-items: stretch;
    gap: 1rem;

    @media screen and (max-width: 762px) {
        flex-direction: column;
    }

    .news-box {
        flex-basis: 24%;
        flex-shrink: 1;

        @media screen and (max-width: 762px) {
            flex-basis: 100%;
            padding: 0 1rem;
        }

        a {
            text-decoration: none;
        }

        div {
            height: 20rem;

            img {
                border-radius: 15px;
                height: 100%;
                width: 100%;
                margin: auto;
                object-fit: cover;
            }
        }

        p {
            text-align: left;
            font-weight: 700;
            color: $gray;
            margin-top: 20px;
            margin-bottom: 0;
        }
    }
}