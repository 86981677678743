@import './../../assets/styles/zcmthemes';

.pagination>.active,
.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  background-color: #47ccde;
  border-color: #47ccde;
  outline: none;
}

.pagination>li>a,
.pagination>li>span {
  color: #47ccde;
}

.disabled-previous {
  display: none !important;
  padding: 8px 16px;
  display: inline-block;
}

.disabled-next {
  display: none !important;
}

@media (max-width: 840px) {
  .disabled-mobile {
    display: none !important;
  }
}

.projects-to-view {
  cursor: pointer;
}

.projects-to-view:hover {
  font-weight: bold;
}

.search-text-main {
  color: $gray;
  font: normal normal bold 1.5rem Raleway;
  margin-bottom: 2rem;
}

.styled-table {
  color: $gray;
  width: 100%;

  &>tbody>tr:nth-of-type(odd) {
    background-color: $secondary;
  }

  &> :not(caption)>*>* {
    padding: 2rem;
  }
}

.styled-access-button {
  border-radius: 50px;
  border-color: #2c9ad9;
  color: #2c9ad9;
}

.map-container {
  flex-grow: 1 !important;
  z-index: 1;
  position: relative;

  &>div {
    width: 100%;
    height: 100%;
  }
}