//spacing
$appContainerMobilePadding: 16px;
$appContainerDesktopPadding: 32px;

// Default colors
$white: #fff;
$gray: #444;
$light-gray: #f1f1f1;
$lighter-gray: #f9f9f9;

// Other styles
$shadow: 0 0 8px rgba(0, 0, 0, .1);
$font-family: 'Raleway', sans-serif !important;

// Theme
$primary: #004aad;
$secondary: #E6F3DC;
$light-green: #73BF48;
$dark-green: #429752;
$text: $gray;
$gradient: linear-gradient(90deg, rgba($primary, 0.05) 0%, rgba($primary, 0.1) 100%);

//Charts
$chart1: #d7df23;
$chart2: #009bdc;
$chart3: #58394E;
$chart4: #99C1B9;
$chart5: #8E7DBE;

//screen sizes
$mobileS: '320px';
$mobileM: '375px';
$mobileL: '425px';
$tablet: '768px';
$wide: '1024px';