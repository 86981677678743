.container {
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 840px) {
        margin-left: 16px;
        margin-right: 16px;
    }
}

.content {
    font-size: 20px;
    max-width: 1399px;
}

.title {
    font-weight: bold;
    margin-bottom: 20px;
}

.videos {
    display: flex;
    justify-content: space-evenly;
    padding: 0px 20px;
}
