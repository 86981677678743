$dividerWave: url('./../../assets/images/dividers/dividerWave.svg');
$dividerMiddle: url('./../../assets/images/dividers/dividerMiddle.svg');
$dividerFooter: url('./../../assets/images/dividers/dividerFooter.svg');

@import './../../assets/styles/zcmthemes';

html,
body {
    /* fix for dividers width */
    overflow-x: hidden;
}

//WARNING: multiple sections have this class below as their default button style. Please, don't remove it.
.styled-link {
    color: $gray;
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    font-weight: 700;


    svg {
        width: 18px;
        margin: 0 0.5rem;
    }
}

.style-news {
    font: normal normal bold 1.5rem Raleway;
}

.style-news-all {
    font-size: 1.25rem;
}

.intro_presentation {
    min-height: calc(90vh - 85px);
    display: flex;



    @media (max-width: 768px) {
        min-height: calc(90vh - 85px);
        flex-flow: column nowrap;
    }

    .presentation_wrapper {
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        .logo {
            flex-basis: 50%;
            display: flex;
            justify-content: center;
            height: auto;

            &img {
                max-width: 100%;
            }
        }

        .about {
            flex-basis: 50%;
            display: flex;
            flex-flow: column nowrap;

            /* UI Properties */
            text-align: left;
            letter-spacing: 0px;
            color: $gray;
            opacity: 1;

            .styled-link {
                color: $light-green;

                svg {
                    margin-left: 0;
                }

                &:hover {
                    color: $dark-green;
                }
            }
        }

        @media screen and (max-width: 1063px) {
            display: inline;
            flex-flow: row wrap;
            align-items: center;

        }

        @media screen and (max-width: 990px) {
            padding-bottom: 6rem;

        }

        @media screen and (max-width: 768px) {
            margin-bottom: 4rem;

            .about,
            .logo {
                flex-basis: 100%;
                width: 70%;
                padding-top: 3rem;
                padding-left: 1rem;
                flex-flow: column nowrap;
            }
        }
    }
}

.intro_history {
    background-color: $primary;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    font-family: $font-family;
    color: $white;
    padding-bottom: 85px;

    &::before {
        // content: $dividerWave;
        content: "";
        background-image: $dividerWave;
        background-repeat: repeat-x;
        position: relative;
        bottom: 4rem;
        left: -6%;
        width: calc(100vw + 18%);
        height: 64px;
        z-index: 1;

        @media (min-width: 1440px) {
            height: calc(32px * 2.5);
            bottom: 4.5rem;
        }
    }

    .how-was-the-project-build-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        margin-top: -3rem;

        .how-was-the-project-build {
            /* These two lines below are just to keep right-side offset. It should be reviewed once new elements are added. */
            flex-basis: 70%;
            width: 70%;
        }
    }
}

.news {
    background-color: $secondary;
    color: $gray;
    font-family: $font-family;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-bottom: 85px;

    &::before {
        content: "";
        background-image: $dividerMiddle;
        background-repeat: repeat-x;
        position: relative;
        bottom: 4rem;

        width: calc(100vw + 10%);
        height: 64px;
        z-index: 1;

        @media (min-width: 1440px) {
            height: calc(32px * 2.5);
            bottom: 4.5rem;
        }
    }

    .news_title {
        display: flex;
        justify-content: space-between;
        margin-top: -3rem;
        padding-bottom: 1.5rem;

        .styled-link {
            &:hover {
                opacity: 0.75;

            }
        }
    }

}

.about-project-wrapper {
    background-color: $dark-green;
    color: $white;
    font-family: $font-family;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;

    &::before {
        // content: $dividerWave;
        content: "";
        background-image: $dividerFooter;
        background-repeat: repeat-x;
        position: relative;
        bottom: 4rem;
        left: -6%;
        width: calc(100vw + 18%);
        height: 64px;
        z-index: 1;

        @media (min-width: 1440px) {
            height: calc(32px * 2.5);
            bottom: 4.5rem;
        }
    }

    &::after {
        // content: $dividerWave;
        content: "";
        background-image: $dividerFooter;
        background-repeat: repeat-x;
        position: relative;
        top: 3.5rem;
        width: calc(100vw + 18%);
        height: 66px;
        transform: rotate(180deg);

    }

    .about_project {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        margin: -3rem 0;

        .platform_text {
            flex-basis: 70%;
        }

        .access_wrapper {
            flex-basis: 30%;
            align-self: center;
            text-align: center;

            .styled-link {
                color: white;
                font-weight: 600;
                margin-bottom: 1rem;

                &:hover {
                    color: $light-green;
                }
            }
        }
    }

}