.loading-container {
    width: 150px;
    position: absolute;
    top: 20px;
    z-index: 4800;
    right: 10px;
    background-color: #455a79;
    border-radius: 5px;
    padding: 10px 10px 10px 10px;
    box-shadow: 0 1px 5px rgb(0 0 0 / 65%);
    font-weight: bold;
    color: #fff;
    display: flex;
    justify-content: space-evenly;
}